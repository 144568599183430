@import "../../mixins.scss";

.contact-us {
  .c-section {
    margin: -140px 0 -80px 0;
    img[alt="leaf-icon"] {
      width: 150px;
      height: auto;
      position: absolute;
      bottom: 0;
      left: 140px;
    }

    a{
        @include para($fw: 500);
        color: var(--primary-text);
        text-decoration: underline;
        text-transform: uppercase;
    }
  }

  .contact-form {
    padding: 0 10%;
    @include flex($jc: space-between);
    margin-bottom: 90px;
    margin-top: 40px;

    .details {
      padding-right: 35px;
      flex: 0.4;
      border-right: 1px solid #999999;

      div, a {
        text-align: center;
        margin: 30px 0;
        img {
          @include flex();
          width: 50px;
          height: auto;
          margin: 0px auto 15px auto;
          padding: 12px 12px;
          background-color: #fff;
          border-radius: 100%;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
        }
        p {
          @include para();
          color: var(--primary-text);
        }
        &:nth-child(3){
          img{
            padding: 12px 16px;
          }
        }
      }
    }

    .form {
      flex: 1.6;
      padding-left: 35px;
      h3 {
        @include heading2();
        color: var(--primary-text);
        text-transform: uppercase;
      }
      p {
        @include para();
        color: var(--primary-text);
        margin: 8px 0;
      }
      form {
        input {
          display: block;
          background: transparent;
          width: 100%;
          margin: 15px 0;
          padding: 10px 5px;
          border: none;
          border-bottom: 1px solid #d9d9d9;
          @include para();
          font-size: 1.2rem;
          color: var(--primary-text);
          &::placeholder{
            color: var(--primary-text);
          }
          &:focus {
            outline: none;
          }
        }
        button {
          @include button($w: 120px);
          padding: 4px;
          margin-top: 15px;
          span{
            padding: 3px 0;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    .contact-form {
      flex-direction: column;
      .details {
        border: none;
        padding: 0;
        margin-bottom: 30px;
        div, a{
          img{
            margin-bottom: 15px;
          }
        }
      }
      .form {
        padding: 0;
      }
    }
  }

  @media (max-width: 900px) {
    .c-section{
      margin: 0 0 80px 0;
    }
  }
}
