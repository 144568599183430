@import "../../mixins.scss";

.portfolio {
  padding: 0 10%;
  margin: 80px auto;
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: #111;
    opacity: 0;
    height: 100%;
    width: 100%;
  }

  @media (max-width: 700px) {
    padding: 0 5%;
    margin: 40px auto;
  }
}

.section {
  @include flex();
  padding: 10%;
  position: relative;

  .left {
    flex: 1;
    padding-right: 20px;
  }

  .right {
    flex: 1;
    padding-left: 20px;
    h3 {
      @include heading2();
      color: var(--primary-text);
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .label {
      @include para($fw: 400);
      font-size: 1.8rem;
      color: var(--primary-text);
      line-height: 29px;
      margin-bottom: 8px;
      span {
        display: block;
        @include para($fw: 400);
        font-size: 1.8rem;
        color: var(--primary-text);
        line-height: 29px;
      }
    }

    .desc {
      @include para();
      color: var(--primary-text);
      margin-bottom: 20px;
      line-height: 29px;
      span {
        display: block;
        @include para();
        color: var(--primary-text);
        margin-top: 8px;
        line-height: 29px;
      }
    }

    button {
      @include button();
    }
  }

  @media (max-width: 900px) {
    padding: 0 3%;
    flex-direction: column;
    .left {
      margin-bottom: 60px;
      padding: 0;
    }
  }
}

.section1 {
  margin: -80px 0 -80px 0;
  img[alt="circle-icon"] {
    width: 150px;
    height: auto;
    position: absolute;
    top: -100px;
    left: 40px;
  }
  @media (max-width: 900px) {
    margin: 80px 0 80px 0;
  }
}

.section2 {
  margin: -80px 0 -80px 0;
  img[alt="circle-icon"] {
    width: 150px;
    height: auto;
    position: absolute;
    top: -10px;
    left: 40px;
  }
  @media (max-width: 900px) {
    margin: 80px 0 80px 0;
  }
}

.achievements-mob {
  display: none;
  margin: 50px auto;
  // max-width: 800px;

  a {
    // flex: 1;
    margin: 0 4px;
    img {
      width: 90px;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1/1;
    }

    &:nth-child(1) {
      display: none;
      img {
        width: 130px;
        aspect-ratio: 1/0.7;
      }
    }
    &:nth-child(2) {
      img {
        width: 380px;
        height: 200px;
        // width: 130px;
        aspect-ratio: 1/0.7;
        border-radius: 6px;
      }
    }
    &:nth-child(3) {
      display: none;
      img {
        width: 300px;
        aspect-ratio: 1/0.32;
      }
    }
    &:nth-child(4) {
      display: none;
      img {
        width: 300px;
        aspect-ratio: 1/0.32;
      }
    }
  }

  @media (max-width: 900px) {
    @include flex();
  }

  @media (max-width: 500px) {
    a {
      img {
        width: 60px;
        height: auto;
        object-fit: cover;
        aspect-ratio: 1/1;
      }

      &:nth-child(2) {
        img {
          // width: 100px;
          width: 300px;
          object-fit: cover;
          aspect-ratio: 1/0.61;
        }
      }
    }
  }
}

.hero{
  min-height: 360px;
}

.cta-blogs{
  @include flex();
  button{
    @include button();
    a{
      width: 300px;
    }
  }
}
@media screen and (max-width: 767px){
  .hero .content{
    justify-content: flex-end !important;
  }
  .hero .content h2{
    line-height: 40px !important;
  }
  .hero .content h2 span{
    line-height: 40px !important;
  }
}